@import 'styles/_sass-includes.scss';

.menu-item, a.menu-item {
  color: inherit;
  font-size: 1.4rem;
  display: inline-block;
  margin-left: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: border 0.25s ease;
  position: relative;
  // media queries
  @include breakpoint($mobile-xsm) {
    font-size: 1.2rem;
  }

  &:first-of-type {
    margin-left: 0;
  }

  .menu-flyout-bridge {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: -3rem;
    left: 0;
    display: none;
  }

  > a {
    border: 1px solid transparent;
  }

  &:hover, &.is-active, > a.is-active {
    .menu-flyout-bridge {
      display: block;
    }
  }

  > a {
    display: inline-block;
    padding: 0.7rem 1.2rem;
    color: inherit;
    text-decoration: none;
  }
}

.selected-menu-item::after {
  content:'';
  height: 2px;
  background-color: #EFEDE4;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: -1;
}
