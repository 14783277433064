@import 'styles/_sass-includes.scss';

.product-detail-tabs {
  text-align: left;
  font-size: 14px;
  margin-left: -3rem;
  margin-right: -3rem;
  font-family: 'Inter' !important;
  @include breakpoint($desktop-sm) {
    margin-left: 0;
    margin-right: 0;
  }
  @include breakpoint($tablet-sm) {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
  @include breakpoint($mobile-md) {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}

.pr_tabs__item {
  button{
    font-family: 'Inter' !important;
  }

  &:first-child{
    // media queries
    @include breakpoint($tablet-sm){
      padding-left: 0;
    }
  }
}



.product-detail-tabs__content {
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  font-family: 'Inter';

  @include breakpoint($desktop-sm) {
    margin-left: 2rem;
  }
  @include breakpoint($tablet-sm) {
    margin: 2rem 0 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  @include breakpoint($mobile-md) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @include breakpoint($mobile-sm) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.product-detail-tabs__content ul li {
  line-height: 2;
  position: relative;
  padding-left: 20px;
  font-family: 'Inter';

  &:before {
    content: "–";
    margin-right: 8px;
    position: absolute;
    left: 0;
  }
}

.product-detail-tabs__content a {
  color: $black;
}

.product-detail-tabs .pr_tabs {
  padding-left: 1rem;
  @include breakpoint($desktop-sm) {
    padding-left: 0;
  }
  @include breakpoint($tablet-sm) {
    padding: 0 40px;
  }
  @include breakpoint($mobile-md) {
    padding: 0 25px;
  }
  @include breakpoint($mobile-sm) {
    padding: 0 16px;
  }
}

.product-detail-tabs .pr_tabs__item {
  font-family: 'Inter';

  flex: inherit;
}
