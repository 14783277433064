.report__wrapper{
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-70) !important;
    svg {
        top: 2px;
        position: relative;
    }
}
