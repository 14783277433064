@import 'styles/_sass-includes.scss';

.checkout__header {
  z-index: 1003;
  position: relative;
}

.checkout__headerinner {
  @extend %container;
  @extend %page-margins;
  border-bottom: inherit;
  margin: 0 auto;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EAEAEA;
}

.checkoutpage .checkout__header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  h3 {
    text-transform: uppercase;
  }
}

.checkout__header__backlink {
  position: absolute;
  left: 50px;
  display: flex;
  align-items: center;
  color: var(--neutral-70);
  flex-grow: 0;
  font-size: 16px;
  // media queries
  @include breakpoint($desktop-sm) {
    left: 40px;
  }
  @include breakpoint($mobile-lg) {
    left: 24px;
  }
  @include breakpoint($mobile-lg) {
    left: 16px;
  }

  .pr_icon {
    margin-right: 8px;
  }
}