.modalOverlay {
    background: #ebebeb;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal {
        max-width: 600px;
        width: 100%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        background: white;
        border-radius: 5px;
        padding: 30px;
        margin: 15px;
        display: flex;
        align-items: start;
        flex-direction: column;
        h2 {
            font-size: 1.5em;
            line-height: 1.16216;
            font-weight: 500;
            font-family: "DM Sans", sans-serif;
            color: inherit;
            letter-spacing: 0px;
            color: black;
            margin-bottom: 15px;
        }
        form {
            display: flex;
            flex-direction: column;
            label {
                padding: 10px 0;
                color: black;
                input[type="checkbox"] {
                    margin-right: 10px;
                }
            }
        }
        .actions {
            width: 100%;
            display: flex;
            justify-content: start;
            gap: 15px;
            button {
                margin-top: 15px;
                height: 50px;
                padding-left: 24px;
                padding-right: 24px;
                border-radius: 16px;
                border: 1px solid #787878;
                font-weight:500;
                font-family: 'Inter', sans-serif;
                &:hover {
                    background: #ececec;
                }
                &.primary {
                    border: 1px solid #1954eb;
                    background: #1954eb;
                    color: #ffffff;
                    &:hover {
                        background: #00278B;
                        border: 1px solid #00278B;
                    }
                }
                &:disabled {
                    border: 1px solid rgba(0, 0, 0, 0);
                    background:rgba(0, 0, 0, 0.16);
                    cursor: not-allowed;
                    &:hover {
                        background:rgba(0, 0, 0, 0.16);
                        border: 1px solid rgba(0, 0, 0, 0);
                    }
                }
            }
        }
    }
}